import { combineReducers } from 'redux';

import postsReducer from '../post/redux/reducer';
import filterCountReducer from '../home/redux/filterCountSlice';

/* ============================================================================
  Combine ALl Reducers
============================================================================= */
const rootReducer = combineReducers({
  Post: postsReducer,
  FilterCount: filterCountReducer,
});

export default rootReducer;
