import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, NavLink, useHistory } from 'react-router-dom';
import {
  ChevronLeft, Filter, List, Search,
} from 'react-feather';
import { RxCross1 } from 'react-icons/rx';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Header,
  SearchInput,
} from '../../common';
import categoriesData from '../../static/categories-data';
import HomeCategorySavedButton from './HomeCategorySavedButton';
import SearchModal from './SearchModal';
import HomeFilterButton from './HomeFilterButton';

import { addFilterCount } from '../redux/filterCountSlice';

/* =============================================================================
<HomeCategoryHeader />
============================================================================= */

const HomeCategoryHeader = ({
  setSearchTxt, searchTxt, content, onReset, onFilterPress, searchScreen, categoryID,
}) => {
  const { categoryId } = useParams();
  const { filterCount } = useSelector(state => state?.FilterCount);
  const category = categoriesData.find((c) => c?.id === categoryId);
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchModal, setSearchModal] = useState(false);
  const _toggleModal = () => setSearchModal(prev => !prev);

  const _handleSearch = (e) => {
    setSearchTxt(e?.target?.value || e);
    _toggleModal();
  };
  const _handleBackPress = () => {
    dispatch(addFilterCount(0));
    history.goBack();
  };
  return (
    <Header stack childrenContainerClass="relative px-4 md:px-0" onBackPress={_handleBackPress}>
      <div className="flex flex-row md:px-20 justify-between items-center w-full justify-end space-x-4">
        <div className="px-20 xsm:px-5 block" />
        <h1 className="text-white xsm:text-xl text-3xl font-semibold text-center">{searchTxt || category?.title}</h1>
        <div className=" md:relative md:top-auto flex mb-auto xsm:pt-1 space-x-4 ">
          <button type="button" onClick={() => (searchScreen ? setSearchTxt('') : _toggleModal())} className="outline-none">
            <Search color="white" size={20} />
          </button>
          <HomeCategorySavedButton categoryId={categoryID || category?.id} customStylesClasses="xsm:hidden" />
        </div>
      </div>
      <div className="max-w-screen-sm text md:mx-auto mt-3 mb-3 md:mb-0 md:mt-5 md:mx-10 text-xs">
        <div className="flex justify-center mx-auto text-center items-center space-x-3">
          <HomeFilterButton title="Filters" variant="white" activeFilters={filterCount} onReset={onReset} customStyleClasses="relative xsm:h-9 flex-1 xsm:rounded-full" customTxtStyles="mini:text-sm" onFilterPress={onFilterPress} icon={<Filter color="white" size={22} className="mini:hidden" />} size="block" />
          <Button
            title="Sort By"
            variant="white"
            customStyleClasses="xsm:h-9 flex-1 xsm:rounded-full"
            customTxtStyles="mini:text-sm"
            icon={<List color="white" size={22} className="mini:hidden" />}
            size="block"
          />
        </div>
      </div>
      <SearchModal
        visible={searchModal}
        onClose={_toggleModal}
        searchTxt={searchTxt}
        onSearch={_handleSearch}
        content={content}
      />
    </Header>
  );
};
/* Export
============================================================================= */
export default HomeCategoryHeader;
