/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterCount: 0,
};

export const filterCountSlice = createSlice({
  name: 'filterCount',
  initialState,
  reducers: {
    addFilterCount: (state, action) => {
      state.filterCount = action.payload;
    },
  },
});

export const { addFilterCount } = filterCountSlice.actions;
export default filterCountSlice.reducer;
