/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Info } from 'react-feather';

import Select from 'react-select';
import { classNames } from '../util/functions';

const ReactSelect = ({
  label, items, name, isMultiSelect, errorText, value, onChange,
  customContainerStyles,
}) => {
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(value) && value?.length) {
      setSelectedValue(value.map(item => ({ label: item, value: item })));
    }
    if (typeof value === 'string' && value.length) {
      setSelectedValue({ label: value, value });
    }
  }, [value]);

  const _handleChange = (selectedItem) => {
    if (isMultiSelect) {
      onChange([...selectedItem.map(item => item.value)]);
    } else {
      onChange(selectedItem?.value);
    }
    setSelectedValue(selectedItem);
  };
  return (
    <div className={classNames(`w-full
      ${customContainerStyles}`)}
    >
      {label && (
      <div className="flex justify-between mb-1 ml-2">
        <label className="block text-lg font-medium text-white">
          {label}
        </label>
      </div>
      )}
      <Select
        value={selectedValue}
        options={items.map((item) => ({ label: item, value: item }))}
        isMulti={isMultiSelect}
        isSearchable={false}
        onChange={_handleChange}
        name={name}
        styles={customStyles}
      />
      {!!errorText && (
      <div className="flex items-center space-x-1 mt-2">
        <Info color="#dc2626" size={20} />
        <p className="text-md font-medium  py-white text-red-500">
          {errorText}
        </p>
      </div>
      )}
    </div>
  );
};
const customStyles = {
  control: (base, state) => ({
    ...base,
    backgroundImage: 'linear-gradient(to bottom, rgba(128,128,128,0.9), black)',
    backgroundColor: 'lightWhite',
    borderRadius: '20px',
    padding: '2px',
    borderColor: state.isFocused ? '#dc2626 !important' : '#fff !important',
    boxShadow: '0 !important',
    '&:hover': {
      borderColor: '#ffff !important',
      outline: 'none',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
};
export default ReactSelect;
