import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  limit, orderBy, query, startAfter, where,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useInfiniteQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { fetchItems, filterCars } from '../../util/functions';
import { emptyFilterForm } from '../../static/filter';
import { postCollectionRef } from '../../config/firebase';
import {
  Container, LinkHeader, PaginationList, PostCard,
} from '../../common';
import HomeFilterModal from '../components/HomeFilterModal';
import categoriesData from '../../static/categories-data';
import HomeCategoryHeader from '../components/HomeCategoryHeader';

import { setPostData as setPostDataAction } from '../../post/redux/actions';
import HomeCategorySavedButton from '../components/HomeCategorySavedButton';
import { useLocation } from '../../Provider/LocationProvider';

/* =============================================================================
<HomeCategoryScreen />
============================================================================= */
const HomeCategoryScreen = ({ setPostData }) => {
  const history = useHistory();
  const resetRef = useRef(null);
  const { categoryId } = useParams();
  const [searchTxt, setSearchTxt] = useState('');
  const [filters, setFilters] = useState(emptyFilterForm);
  const [filtersModal, setFiltersModal] = useState(false);
  const [activeFilters, setActiveFilters] = useState(0);
  const { location } = useLocation();
  const _toggleFiltersModal = (count) => setFiltersModal(prev => !prev);
  const category = categoriesData.find((c) => c?.id === categoryId);

  const queryKey = ['categoryPosts', categoryId];

  const {
    data,
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: (page) => fetchItems(page, location, categoryId),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });
  useEffect(() => {
    if (location?.radius) {
      const fetchData = async () => {
        try {
          await refetch(); // First refetch to fetch the data
          await refetch(); // Second is ensure that fetched data is latest
        } catch (error) {
          toast.error(error);
        }
      };
      fetchData();
    }
  }, [location, location?.radius, fetchNextPage, refetch]);
  const flattenData = data?.pages
    ? data?.pages
      ?.flatMap(page => [...page?.data])
      .sort((a, b) => (!location?.radius ? Number(b?.isFeatured) - Number(a?.isFeatured) : 1))
    : [];

  // const searchFiltered = useMemo(() => (searchTxt
  //   ? flattenData?.filter(p => {
  //     const searchFilter = `${p?.year} ${p?.make} ${p?.model} `.toLowerCase();
  //     return searchFilter.includes(searchTxt?.trim()?.toLowerCase());
  //   })
  //   : filterCars(flattenData, filters)),
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // [searchTxt, filters, flattenData?.length, filters.makes, filters.miles]);
  const searchFiltered = searchTxt
    ? flattenData?.filter(p => {
      const searchFilter = `${p?.year} ${p?.make} ${p?.model} `.toLowerCase();
      return searchFilter.includes(searchTxt?.trim()?.toLowerCase());
    })
    : filterCars(flattenData, filters);
  const renderItem = (item, index) => (
    <PostCard
      item={item}
      key={item.id}
      onPress={() => {
        setPostData({ index, data: searchFiltered });
        history.push('/post/data');
      }}
    />
  );
  const links = [
    {
      name: 'Home',
      url: '/home',
    },
    {
      name: category?.title,
      url: `/category/${categoryId}`,
    },
  ];
  const _handleReset = () => {
    resetRef?.current?.onReset();
  };
  return (
    <Container>
      <Helmet>
        <title>{`Carsnus | ${category?.title}`}</title>
      </Helmet>
      <HomeCategoryHeader
        searchTxt={searchTxt}
        setSearchTxt={setSearchTxt}
        onFilterPress={_toggleFiltersModal}
        activeFilters={activeFilters}
        onReset={_handleReset}
      />
      <div className="flex flex-1 justify-between mx-5 xl:mx-12">
        <div className="">
          <div className="hidden md:block">
            <LinkHeader contents={links} />
          </div>
        </div>
        <HomeCategorySavedButton customStylesClasses="pt-2 pb-3" categoryId={categoryId} />
      </div>
      <PaginationList
        horizontal
        center={false}
        data={searchFiltered}
        loading={isLoading}
        styles="mx-5 xsm:my-1 sm-1:my-1 xl:mx-12 xsm:mt-1"
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        emptyText="No post found in this category"
      />
      <HomeFilterModal
        filters={filters}
        visible={filtersModal}
        category={categoryId}
        onFilterChange={setFilters}
        onClose={() => setFiltersModal(false)}
        ref={resetRef}
      />
    </Container>
  );
};

const mapDispatchToProps = {
  setPostData: setPostDataAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(HomeCategoryScreen);
