/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
// eslint-disable-next-line no-sequences
/* eslint-disable max-len */
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import {
  Button,
  FormDropDown,
  Input,
  LocationButton,
  Modal,
} from '../../common';
import {
  emptyFilterForm,
  filterFormFields,
  searchFilterFormFields,
} from '../../static/filter';
import TooltipSlider from '../../common/ToolTipSlider';
import { getActiveFilterCount } from '../../util/functions';
import { addFilterCount } from '../redux/filterCountSlice';
import FormSelect from '../../common/FormSelect';

/* =============================================================================
<HomeFilterModal />
============================================================================= */
const HomeFilterModal = forwardRef(({
  visible,
  filters,
  onClose,
  category,
  onFilterChange,
}, ref) => {
  const fields = category === 'search' ? searchFilterFormFields : filterFormFields[category];

  const dispatch = useDispatch();
  const {
    reset, control, register, handleSubmit, watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(filterFormScheema),
    defaultValues: filters || emptyFilterForm,
  });
  const filterValues = watch();
  console.log('🚀 ~ filterValues:', filterValues);
  const count = getActiveFilterCount(filterValues);
  const onReset = () => {
    reset(emptyFilterForm);
    onFilterChange(emptyFilterForm);
    dispatch(addFilterCount(0));
    onClose();
  };
  useImperativeHandle(ref, () => ({
    onReset,
  }));
  const onSubmit = (values) => {
    onFilterChange(values);
    dispatch(addFilterCount(count));
    onClose();
  };
  const FilterCount = () => (
    <div className="px-3 py-1 rounded-full bg-[#555555]">
      <p className="text-white text-semibold xsm:text-sm text-center font-bold">
        {count}
      </p>
    </div>
  );
  return (
    <Modal
      visible={visible}
      heading="Filters"
      onClose={onClose}
      childrenStyles="pt-0"
      transitionChildStyles="xsm:p-0 xsm:h-full"
      customStyles="xsm:relative xsm:h-full w-full xsm:rounded-none"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 overflow-y-scroll  px-2 pb-5"
        style={{ height: '73vh' }}
      >
        <div className="flex justify-end ">
          {/* <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationButton showDistance value={value} onChange={e => console.log('onChange', e)} />
            )}
            name="location"
          /> */}
        </div>
        {fields?.map((field) => {
          if (field.type === 'text') {
            return (
              <Input label={field.label} formProps={register(field.name)} />
            );
          }
          if (field.type === 'number' && field.name === 'year') {
            return (
              <div className="flex space-x-4">
                <Input label="Year (from)" formProps={register('yearFrom')} errorText={errors?.yearFrom?.message} />
                <Input label="Year (to)" formProps={register('yearTo')} errorText={errors?.yearTo?.message} />
              </div>
            );
          }
          if (field.type === 'slider') {
            return (
              <div className="">
                <TooltipSlider
                  label={field.label}
                  key={field.name}
                  data={field.data}
                  control={control}
                  name={field.name}
                  min={0}
                  max={150000}
                  range
                  defaultValue={filters.miles}
                  error={[field.name]?.message}
                />
              </div>

            );
          }
          if (field.type === 'number' && field.name === 'price') {
            return (
              <div className="flex space-x-4">
                <Input label="Price (from)" formProps={register('priceFrom')} errorText={errors?.priceFrom?.message} />
                <Input label="Price (to)" formProps={register('priceTo')} errorText={errors?.priceTo?.message} />
              </div>
            );
          }

          if (field.type === 'modal') {
            return (
              <FormSelect
                key={field.name}
                data={field.data}
                label={field.label}
                control={control}
                name={field.name}
                isMultiSelect={field.isMultiSelect}
                error={[field.name]?.message}
              />
            );
          }
          return null;
        })}
      </form>
      <div className="sticky xsm:absolute bottom-0 xsm:px-5 left-0 right-0 bg-black flex items-center space-x-5 pt-5 py-2">
        <Button
          size="block"
          variant="outline"
          title="Reset"
          icon={<FilterCount />}
          onClick={onReset}
        />
        <Button
          size="block"
          title="APPLY"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </Modal>
  );
});

const filterFormScheema = yup.object().shape(
  {
    yearFrom: yup.string().when('yearTo', {
      is: (yearTo) => !!yearTo,
      then: () => yup
        .string()
        .required('Year (from) is required'),
      otherwise: () => yup.string(),
    }),
    yearTo: yup.string().when('yearFrom', {
      is: (yearFrom) => !!yearFrom,
      then: () => yup
        .string()
        .required('Year (to) is required'),
      otherwise: () => yup.string(),
    }),
    priceFrom: yup.string().when('priceTo', {
      is: (priceTo) => !!priceTo,
      then: () => yup
        .string()
        .required('Price (from) is required'),
      otherwise: () => yup.string(),
    }),
    priceTo: yup.string().when('priceFrom', {
      is: (priceFrom) => !!priceFrom,
      then: () => yup
        .string()
        .required('Price (to) is required'),
      otherwise: () => yup.string(),
    }),
  },
  [
    ['yearFrom', 'yearTo'],
    ['priceFrom', 'priceTo'],
  ],
);
/* Export
============================================================================= */
export default HomeFilterModal;
